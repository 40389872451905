<template>

  <div data-transfer="true" class="v-transfer-dom">
    <!-- 红包功能暂时关闭 -->
      <!-- <div id="isquan">
      <div class="ivu-modal-wrap red-packet-modal" style="z-index: 1011">
        <div class="ivu-modal" style="width: 520px;position: fixed;top: 20%;z-index: 1100;display: none">
          <div class="ivu-modal-content">
            <a class="ivu-modal-close" style="
                  display: flex;
                  justify-content: flex-end;
                  padding-right: 20px;
                  padding-top: 10px;
              "
              ><i style="font-size: 22px;" @click="outredPrize">X</i
            ></a>
            <div class="ivu-modal-header"><div></div></div>
            <div class="ivu-modal-body">
              <div class="content">
                <div class="red-logo">
                  <img src="https://r8skins.com/img/logo.6a88e67b.png" alt="" />
                </div>

                <div v-if="isredkai" class="red-content">
                  <div class="guid_btn_box">
                    <span class="btn" @click="hongbaoguize">口令红包规则＞</span
                    ><span class="btn"
                      ><a
                        href="https://jq.qq.com/?_wv=1027&amp;k=M0X42pGK"
                        target="_blank"
                        style="color: rgb(255, 255, 255)"
                      >
                        没有口令猛戳这＞
                      </a></span
                    >
                  </div>
                  <div class="codeIpt">
                    <input type="text" placeholder="输口令，抢红包" />
                  </div>
                  <div class="join"></div>
                  <div class="red-bag-btn" @click="openEnvelope"><span class="kai">開</span></div>
                  <div class="step_box">
                    <div class="step_dian">
                      <div class="heng"></div>
                      <div class="dian"></div>
                      <div class="dian"></div>
                      <div class="dian"></div>
                    </div>
                    <div class="step_dian_w">
                      <span class="word">获得口令</span
                      ><span class="word">输入口令</span
                      ><span class="word">抢到红包</span>
                    </div>
                  </div>
                </div>
                <div v-else style="display: flex;justify-content: center;">
                  <div class="rollbox_data">
                    <h1 style="font-size: 22px;">恭喜获得:</h1>
                    <div class="rollbox_data_img">
                        <img src="http://api.r8skins.com/uploads/20210429/9fa00a3c5b49a7fd5d3783c11604a6d4.png" alt="">
                    </div>
                    <h4>P250</h4>
                    <p>(战痕累累)</p>
                    <p style="color: #171E28;">已自动存放到仓库</p>
                  </div>
                </div>

                <div class="draw_box">
                  <div class="red_bag_rule">
                    <a class="ivu-modal-close"
                      ><i class="ivu-icon-ios-close" style="
                          display: flex;
                          justify-content: flex-end;
                          padding-top: 10px;
                          padding-right: 20px;
                          font-size: 20px;"
                          @click="outhongbaoguize"
                          >X</i
                    ></a>
                    <p class="title">红包口令规则</p>
                    <div class="rule_con">
                      <p>
                        1、输入红包口令，可获得随机硬币奖励，硬币用于平台内活动，不可直接兑换商城皮肤。
                      </p>
                      <p>
                        2、同一台设备或网络环境最多可以从 1 个红包中，获得 2
                        次奖励。
                      </p>
                      <p>
                        3、口令可以通过官方FB账号，Twitter账号，活动等方式获得，口令和红包有时效性。
                      </p>
                      <p>4、领取红包之后，您将失去退款机会。</p>
                      <p>
                        5、红包最终解释权归本站所有，任何通过非法手段牟利都会导致资产冻结或账号封禁。
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="ivu-modal-footer"><div></div></div>
          </div>
        </div>
      </div>
      </div> -->
  </div>
</template>

<script>
export default {
    data(){
        return{
            // isredkai:true,
        }
    },
    methods:{
        //关闭红包
        // outredPrize(){
        // document.getElementsByClassName('ivu-modal')[0].style.display="none";
        // document.getElementById('isquan').classList.remove("backghui");
        // this.isredkai = true
        // },
        // //红包规则
        // hongbaoguize(){
        // document.getElementsByClassName('draw_box')[0].classList.add("active")
        // },
        // outhongbaoguize(){
        // document.getElementsByClassName('draw_box')[0].classList.remove("active")
        // },
        // //开红包
        // openEnvelope(){
        // this.isredkai = false
        // },
    },

}
</script>

<style lang="less" scoped>
// @import url('../assets/csscopy/index.css');
//红包模块
.backghui{
    width: 100%;
    height: 100vh;
    background: rgba(0,0,0,.6);
    position: fixed;
    top: 0;
    left: 0;
    z-index: 12;
}
.rollbox_data{
    margin-right: 5px;
    border-radius: 5px;
    text-align: center;
    margin-top: 15px;
    color: #DEDDDB;
    p{
        font-size: 12px;
    }
}
.rollbox_data_img{
    background-repeat:no-repeat;
    background-size: 100% 100%;
    height: 100px;
    border-radius: 5px;
    img{
        height: 100px;
        border-radius: 5px;
        background-repeat: no-repeat;
        background-size: 100% 100%;
    }
}
</style>