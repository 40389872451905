<template>
  <div class="main">
    <div id="integral">
      <div class="integral_type_show_div">
        <ul class="integral_type_show_ul">
          <li class="active">积分兑换</li>
        </ul>
      </div>
      <div class="ant-spin-nested-loading" style="width: 100%">
        <div class="ant-spin-container">
          <ul class="exchange-goods list app-container">
            <!-- <li class="exchange-good">
              <div
                class="img"
                style="background-image: url('https://r8skins.com/img/change_back.331bdf15.png')"
              >
                <div
                  class="box"
                  style="
                    background-image: url('http://api.r8skins.com/uploads/20210501/b728e01a1bf6b27e805f28ba58ee1a27.png');
                  "
                ></div>
              </div>
              <div class="span">
                <div title="AK-47 | 幻影破坏者 (崭新出厂)" class="awardName">
                  AK-47 | 幻影破坏者 (崭新出厂)
                </div>
                <div class="awardPrice">
                  <span class="points"> 128.00积分</span>
                </div>
              </div>
              <div class="button_of_change"><button>确认兑换</button></div>
            </li> -->
            <li class="exchange-good" v-for="(item,index) in intedata.data" :key="index">
              <div
                class="img"
                :style="{backgroundImage:'url('+item.back_logo+')'}"
              >
                <div
                  class="box"
                  :style="{backgroundImage:'url('+item.logo+')'}"

                ></div>
              </div>
              <div class="span">
                <div :title=item.title class="awardName">
                  {{item.title}}
                </div>
                <div class="awardPrice">
                  <span class="points"><i></i>{{item.integral}}</span>
                </div>
              </div>
              <div class="button_of_change"><button @click="getShop(item.id,item.integral)">确认兑换</button></div>
            </li>
            
          </ul>
        </div>
      </div>
      <div class="pageBox">
        <ul class="ivu-page">
          <li title="1" class="ivu-page-item ivu-page-item-active">
            <el-pagination
              small
              background
              layout="prev, pager, next"
              @current-change="pagedata"
              :total="total"
              :page-size="limit"
              >
            </el-pagination>
          </li>
        </ul>
      </div>
    </div>

    <!-- 红包 -->
    <Redenvelopes></Redenvelopes>
  </div>
</template>

<script>
import Redenvelopes from "../components/Redenvelopes";

export default {
  components: {
    Redenvelopes
  },
  data() {
    return {
      intedata:'',//页面数据
      total:10,//分页测试
      page:1,//分页
      limit:10,//一页多少条
    };
  },
  methods: {
    // 红包
    redPrize(){
      document.getElementsByClassName('ivu-modal')[0].style.display="block";
      document.getElementById('isquan').classList.add("backghui")
    },
    //拿到当前页数
    pagedata(key){
      this.page = key
      this.getintedata()
    },
    //获取页面数据
    getintedata(){
      this.$axios({
        url:'pond/integral/List',
        method:'post',
        data:{
          page:this.page,
          limit:this.limit
        }
      }).then( res => {
        if( res.data.status == 200){
          this.intedata = res.data.data
          this.total = res.data.data.count
        }
      })
    },
    //兑换
    getShop(id,integral){
      this.$axios({
        url:'pond/integral/getShop',
        method:'post',
        data:{
          id:id
        }
      }).then( res => {
        if(res.data.status == 200){
          //积分减除
          let inte = this.$store.state.userdata.integral - integral
          inte = inte.toFixed(2)
          this.$store.commit("userintegral_up", inte);
          this.$message({
            showClose: true,
            message: res.data.msg,
            type: "success",
          });
        }else{
          this.$message({
            showClose: true,
            message: res.data.msg,
            type: "error",
          });
        }
      })
    },
  },
  mounted() {
    this.getintedata()
  },
};
</script>

<style scoped lang='less'>

@import url("../assets/csscopy/integral.css");
@media screen and (max-width: 800px) {
    .exchange-goods{
      max-width: 100%;
      min-width: 100%;
      .exchange-good{
        width: 47%;
        margin: 10px 5px;
      }
    }
}
</style>